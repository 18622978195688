import difference from "lodash/difference";
import { useState } from "react";
import { Button, Input, Table } from "reactstrap";
import { Search } from "ui";
import Modal, { ModalProps } from "ui/Modal";
import ParticipantCell from "ui/ParticipantDisplay/ParticipantCell";
import getDate from "utils/getDate";

const MOCK: {
  id: number | string;
  contract_number: string;
  contract_date: string;
  client: {
    name?: string;
    inn?: string;
  };
  contractor: {
    name?: string;
    inn?: string;
  };
  start_date: string;
  end_date: string;
  status: number;
  amount: number;
  impressions_fact: number;
  impressions_plan: number;
  creative_statistics_amount: string;
}[] = [
  {
    id: 1,
    contract_number: "12",
    contract_date: "2020",
    client: {
      name: "hello world",
      inn: "12345678"
    },
    contractor: {
      name: "contractor test",
      inn: "896756"
    },
    start_date: "2020",
    end_date: "2022",
    status: 1,
    amount: 2,
    impressions_fact: 33,
    impressions_plan: 12,
    creative_statistics_amount: "23456"
  },
  {
    id: 2,
    contract_number: "345",
    contract_date: "2020",
    client: {
      name: "hello world",
      inn: "12345678"
    },
    contractor: {
      name: "contractor test",
      inn: "896756"
    },
    start_date: "2020",
    end_date: "2022",
    status: 1,
    amount: 2,
    impressions_fact: 33,
    impressions_plan: 12,
    creative_statistics_amount: "23456"
  }
];

const ALL_MOCK_IDS = MOCK.map(({ id }) => id);

interface IProps {
  isOpen: ModalProps["isOpen"];
  toggle: ModalProps["toggle"];
  setValue: (_: (string | number)[]) => void;
  currentValue: (string | number)[];
}

const ConnectStatToInvoiceModal = ({ isOpen, toggle, setValue, currentValue }: IProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [tempIds, setTempIds] = useState(currentValue || []);

  const onSearchClick = (newSearch: string) => {
    setSearchValue(newSearch);
    console.log(newSearch);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Добавление статистики к акту"
      style={{
        maxWidth: "1500px"
      }}
      footer={
        <>
          <Button
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              toggle();
              setTimeout(() => {
                setTempIds(currentValue);
              }, 200);
            }}
          >
            Отменить
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              toggle();
              setValue(tempIds);
            }}
          >
            Выбрать
          </Button>
        </>
      }
    >
      <Search
        onSearchClick={onSearchClick}
        currentQuery={searchValue}
        placeholder="Поиск по ID, заказчику, исполнителю, номеру договора"
      />
      <Table hover className="mb-0 mt-3" responsive>
        <thead>
          <tr>
            <th>
              <Input
                type="checkbox"
                onChange={() => {
                  difference(ALL_MOCK_IDS, tempIds).length !== 0
                    ? setTempIds(ALL_MOCK_IDS)
                    : setTempIds([]);
                }}
                checked={difference(ALL_MOCK_IDS, tempIds).length === 0}
              />
            </th>
            <th>ID</th>
            <th>№&nbsp;договора</th>
            <th>Дата договора</th>
            <th>Заказчик</th>
            <th>Исполнитель</th>
            <th>Начало периода</th>
            <th>Окончание периода</th>
            <th>Статус</th>
            <th>Кол-во креативов</th>
            <th>Фактическое число показов</th>
            <th>Общее число показов</th>
            <th>Общая стоимсоть показов</th>
          </tr>
        </thead>
        <tbody>
          {MOCK.map((data) => (
            <tr key={data.id}>
              <td>
                <Input
                  type="checkbox"
                  onChange={() => {
                    if (tempIds.includes(data.id)) {
                      setTempIds((prev) => prev.filter((id) => id !== data.id));
                    } else {
                      setTempIds((prev) => Array.from(new Set([...prev, data.id])));
                    }
                  }}
                  checked={tempIds.includes(data.id)}
                />
              </td>
              <td>{data.id}</td>
              <td>{data.contract_number}</td>
              <td>{getDate(data.contract_date)}</td>
              <ParticipantCell
                id={`client${data.id}`}
                name={data.client.name}
                inn={data.client.inn}
                // ord={}
              />
              <ParticipantCell
                id={`contractor${data.id}`}
                name={data.contractor.name}
                inn={data.contractor.inn}
                // ord={}
              />
              <td>{getDate(data.start_date)}</td>
              <td>{getDate(data.end_date)}</td>
              <td>{data.status}</td>
              {/* ^StatusCell^ */}
              <td>{data.amount}</td>
              <td>{data.impressions_fact}</td>
              <td>{data.impressions_plan}</td>
              <td>{data.creative_statistics_amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
};

export default ConnectStatToInvoiceModal;
