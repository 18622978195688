import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";

export type TDangerHiglightCondition = { [n: string]: string | number };

const calulateIsDisabledByOneCondition = (objItem: string, condition: TDangerHiglightCondition) => {
  return isEqual({ ...JSON.parse(objItem), ...condition }, JSON.parse(objItem));
};

const createOptions = (
  obj: { [n: string]: string },
  dangerHighlight?: TDangerHiglightCondition | TDangerHiglightCondition[],
  isValueString?: boolean
) =>
  Object.keys(obj).map((item) => {
    let isDisabled = false;
    if (dangerHighlight) {
      if (isArray(dangerHighlight)) {
        isDisabled = dangerHighlight.some((condition) =>
          calulateIsDisabledByOneCondition(obj[item], condition)
        );
      } else {
        isDisabled = calulateIsDisabledByOneCondition(obj[item], dangerHighlight);
      }
    }
    return {
      value: isValueString ? item : +item.replace(/[^0-9]/g, ""),
      label: obj[item] ? obj[item] : "(нет)",
      isDisabled
    };
  });

export default createOptions;
