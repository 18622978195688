import React from "react";
import { TSearchParamsRequired } from "types/TSearchParams";
import { TStatisticsFilter } from "types/TStatisticsFilter";
import { useQuery } from "react-query";
import { getStatisticsList } from "api/statistics_list";

const useLoadStatisticsList = ({
  searchParams
}: {
  searchParams: TSearchParamsRequired<TStatisticsFilter>;
}) => {

  const {
    isLoading,
    error,
    data,
    refetch,
  } = useQuery({
    queryKey: [
      "statistics_list",
      searchParams.page,
      searchParams.page_size,
      searchParams.search,
      searchParams.filter,
      searchParams.ordering
    ],
    queryFn: () =>
      getStatisticsList({
        page: searchParams.page,
        size: searchParams.page_size,
        search: searchParams.search,
        filter: searchParams.filter,
        ordering: searchParams.ordering
      })
  });

  return {
    isLoading, error, data, refetch
  }

};

export default useLoadStatisticsList;
