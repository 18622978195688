import axios from "axios";
import Cookies from "js-cookie";
import {Statistic} from "../models";

export type StatisticPostFields = Pick<Statistic, 'contract' | 'start_date' | 'end_date' | 'status' | 'statistics_file'>

export const addStatistics = ({ statistic }: { statistic: StatisticPostFields }) => {
    return axios
        .post(`/api/v2/statistics/`, statistic, {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken"),
                "Content-Type": "application/json;charset=utf-8"
            }
        })
        .then((res) => {
            return res;
        });
};
