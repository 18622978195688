import {useQuery} from "react-query";
import {getStatistics} from "api/statistics_item";
import {ID} from "../../../models";

export const useLoadStatistics = (id: ID) => {
    const { data } = useQuery({
        queryKey: ["statistics_item", id],
        queryFn: () => getStatistics(id)
    });

    return {statistics: data?.data}
};
