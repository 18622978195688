import EditorFooter from "Layout/EditorFooter";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {Button} from "reactstrap";
import TFormAddPossibleStatuses from "types/TFormAddPossibleStatuses";
import {scrollToError} from "utils/scrollToError";
import {StatisticsForm, TStatisticsForm} from "./components/form";
import {useMutation} from "react-query";
import onErrorMutation from "utils/onErrorMutation";
import {AxiosError} from "axios";
import {addStatistics} from "api/statistics_add";
import {useNavigate} from "react-router-dom";

const StatisticsAddForm = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState<TFormAddPossibleStatuses>("ACTIVE");
  const [amountFromAllocationsFile, setAmountFromAllocationsFile] = useState<number>();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {} as TStatisticsForm
  });

  const { mutate: add } = useMutation({
    mutationFn: addStatistics,
    onSuccess: () => navigate('/statistics'),
    onError: (err) => {
      onErrorMutation(err as AxiosError, setError);
    }
  });

    const contract = watch('contract')
    const start_date = watch('start_date')
    const end_date = watch('end_date')
    const statistics_file = watch('statistics_file')

  const onSubmit = handleSubmit(
    (_, event) => {
      const statisticsBody = {
          contract: Number(contract),
          start_date,
          end_date,
          statistics_file,
          status: status === 'DRAFT' ? 2 : 1
      }
      add({statistic: statisticsBody})
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  return (
    <div>
      <h1>Загрузить статистику без акта</h1>
      <StatisticsForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={
          <EditorFooter
            left={
              <Button type="submit" color="primary" onClick={() => setStatus("DRAFT")}>
                Сохранить черновик
              </Button>
            }
            right={
              <>
                <Button href="#/statistics" tag="a" color="primary" outline>
                  Отменить
                </Button>
                <Button type="submit" color="danger" onClick={() => setStatus("ACTIVE")}>
                  Отправить в ЕРИР
                </Button>
              </>
            }
          />
        }
        watch={watch}
        setAmountFromAllocationsFile={setAmountFromAllocationsFile}
        setValue={setValue}
        setError={setError}
        clearErrors={clearErrors}
        isDraft={status === "DRAFT"}
      />
    </div>
  );
};

export default StatisticsAddForm;
