import { useQuery } from "react-query";
import { getCountryCode } from "api/get_country_codes";
import { APIData, CountryCodeApi } from "../api/types";
import { useMemo } from "react";
import createOptions from "./createOptions";

type UseCountryCodeOptionsReturnType = {
  options: {
    value: string | number,
    label: string,
    isDisabled?: boolean,
  }[],
  isError: boolean,
  isLoading: boolean,
}
export const useCountryCodeOptions = () => {
  const {data, isError, isLoading} = useQuery<APIData<CountryCodeApi>>({
    queryKey: ['country_code'],
    queryFn: getCountryCode
  })

  const countryCodes = data?.data.country_codes

  const countryCodeList = useMemo(() => {
    if (countryCodes) {
      const formattedCodes = countryCodes?.reduce(
        (acc, country) => {
          acc[country.country_code] = `${country.full_country_name || country.country_name} (${
            country.country_code
          })`;
          return acc;
        },
        {} as { [key: string]: string }
      )

      const options = createOptions(
        formattedCodes,
        undefined,
        true);

      return options;
    }
    return [];
  }, [countryCodes])

  return {
    options: countryCodeList,
    isError,
    isLoading
  }
};
