import { ID } from "models";
import { ReactElement } from "react";
import { Col, Label, LabelProps, Row } from "reactstrap";
import QuestionTooltip from "ui/QuestionTooltip";

interface Props extends LabelProps {
  title?: string;
  isRequired?: boolean;
  info?: {
    text: string | ReactElement;
    id: ID;
  };
  isBig?: boolean;
  buttonInTitle?: ReactElement;
}

const FieldLabel = ({ title, isRequired, info, isBig, buttonInTitle, ...rest }: Props) => {
  if (!title) return null;

  if (isBig && !buttonInTitle)
    return (
      <p className="fs-4 text-center mb-1 mt-3 font-weight-bold">
        {title}
        {isRequired ? <span style={{ color: "var(--bs-red)" }}> *</span> : ""}
      </p>
    );

  if (isBig && buttonInTitle)
    return (
      <Row className="mt-3 mb-2">
        <Col></Col>
        <Col md={6}>
          <p className="fs-4 text-center mb-1 mt-0 font-weight-bold">
            {title}
            {isRequired ? <span style={{ color: "var(--bs-red)" }}> *</span> : ""}
          </p>
        </Col>
        <Col className="d-flex justify-content-center justify-content-md-end align-items-baseline">
          {buttonInTitle}
        </Col>
      </Row>
    );

  return (
    <Label {...rest}>
      {title}
      {isRequired ? <span style={{ color: "var(--bs-red)" }}> *</span> : ""}
      {info ? (
        <QuestionTooltip text={info.text} id={info.id.toString()} style={{ marginLeft: "10px" }} />
      ) : (
        ""
      )}
    </Label>
  );
};

export default FieldLabel;
