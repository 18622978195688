import axios from "axios";
import Cookies from "js-cookie";
import { TFileUploadFailResponse, TFileUploadResponse } from "types/ResponseTypes";

type TUploadError = {
  response?: {
    data: TFileUploadFailResponse;
  };
};

export const uploadFile = (
  file: FormData
): Promise<void | TFileUploadResponse | TFileUploadFailResponse> => {
  return axios
    .post("/api/v1/file_upload/", file, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "attachment"
      }
    })
    .then(({ data }: { data: TFileUploadResponse }) => data)
    .catch((err: TUploadError) => {
      if (err.response?.data) return err.response.data;
      console.error(err);
      return { status: false };
    });
};

export const uploadAllocations = (
  file: FormData
): Promise<void | TFileUploadResponse | TFileUploadFailResponse> => {
  return axios
    .post("/api/v1/file_upload/?mode=ALLOCATIONS", file, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "attachment"
      }
    })
    .then(({ data }: { data: TFileUploadResponse }) => data)
    .catch(({ response: { data } }: { response: { data: TFileUploadFailResponse } }) => data);
};

export const uploadCreativeStatistic = (
  file: FormData
): Promise<void | TFileUploadResponse | TFileUploadFailResponse> => {
  return axios
    .post("/api/v1/file_upload/?mode=INVOICELESS_STATISTIC", file, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "attachment"
      }
    })
    .then(({ data }: { data: TFileUploadResponse }) => data)
    .catch(({ response: { data } }: { response: { data: TFileUploadFailResponse } }) => data);
};
