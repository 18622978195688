import { useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import ImportModal from "ui/ImportModal";

type Props = {
  onExport: () => void;
  exportLink: string;
  refetch: () => Promise<any>;
};

export const StatisticListingActions = ({ onExport, exportLink, refetch }: Props) => {
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
  const toggle = () => setExportDropdownOpen((prevState) => !prevState);

  const [importModalOpen, setImportModalOpen] = useState(false);
  const toggleImportModal = () => setImportModalOpen((prevState) => !prevState);

  return (
    <>
      <DropdownMenu>
        <Dropdown direction="end" isOpen={exportDropdownOpen} toggle={toggle}>
          <DropdownToggle
            onClick={onExport}
            tag="button"
            type="button"
            className="dropdown-item d-flex justify-content-between align-items-center"
          >
            <span>Экспорт</span>
            <FaChevronRight />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a" href={exportLink || ""}>
              Скачать
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <DropdownItem tag="button" onClick={toggleImportModal}>
          Импорт
        </DropdownItem>
      </DropdownMenu>
      <ImportModal
        isOpen={importModalOpen}
        toggle={toggleImportModal}
        exampleLink="static/core/import_templates/statistics.xlsx"
        exampleTitle="Шаблон импорта статистики"
        importVariant="statistics"
        refetch={refetch}
        apiVersion={'v2'}
      />
    </>
  );
};
