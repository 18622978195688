import { getContractList } from "api/contract_list";
import { getCreativeV2List } from "api/creative_v2_list";
import { APIListData } from "api/types";
import { AxiosError } from "axios";
import uniq from "lodash/uniq";
import { Contract, CreativeV2, ID } from "models";
import { useState } from "react";
import { useQuery } from "react-query";
import { TCreativeFilter } from "types/TCreativeFilter";
import { TSearchParamsRequired } from "types/TSearchParams";

const useLoadCreatives = ({
  searchParams
}: {
  searchParams: TSearchParamsRequired<TCreativeFilter>;
}) => {
  const [count, setCount] = useState(0);
  const [contractIds, setContractIds] = useState<ID[]>([]);

  const {
    isLoading: creativesLoading,
    isFetching: creativesFetching,
    error: creativesError,
    data: creativesInfoList,
    refetch: creativesRefetch
  } = useQuery<APIListData<CreativeV2>, AxiosError>({
    queryKey: [
      "creative_list",
      searchParams.page,
      searchParams.page_size,
      searchParams.search,
      searchParams.filter,
      searchParams.ordering
    ],
    queryFn: () =>
      getCreativeV2List({
        page: searchParams.page,
        size: searchParams.page_size,
        search: searchParams.search,
        filter: searchParams.filter,
        ordering: searchParams.ordering
      }),
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response?.status) {
        if (count !== response.data.count) setCount(response.data.count);

        const newContractIds: ID[] = [];
        response.data.results.forEach((cr) => {
          if (cr.extra_fields?.group?.final_contract)
            newContractIds.push(cr.extra_fields.group.final_contract);
          if (cr.extra_fields?.group?.initial_contract)
            newContractIds.push(cr.extra_fields.group.initial_contract);
        });
        setContractIds(uniq(newContractIds));
      }
    }
  });

  const {
    isLoading: contractsLoading,
    isFetching: contractsFetching,
    error: contractsError,
    data: contractsInfoList
  } = useQuery<APIListData<Contract>, AxiosError>({
    queryKey: ["creative_group_list", contractIds],
    queryFn: () =>
      getContractList({
        filter: {
          id: contractIds
        },
        size: contractIds.length
      }),
    enabled: contractIds.length > 0,
    refetchOnWindowFocus: false
  });

  return {
    isLoading: creativesLoading || creativesFetching || contractsLoading || contractsFetching,
    error: creativesError || contractsError,
    data: {
      creatives: creativesInfoList?.data?.results || ([] as CreativeV2[]),
      contracts: contractsInfoList?.data?.results || ([] as Contract[])
    },
    creativesRefetch,
    count
  };
};

export default useLoadCreatives;
