import { getParticipantList } from "api/participant_list";
import { InvoiceFilterDV } from "constants/defaultValues/InvoiceFilterDV";
import {
  combinedStatusOptions,
  onlyErirOptions
} from "constants/selectOptions/CombinedStatusOptions";
import ParticipantClientRolesOptions from "constants/selectOptions/ParticipantClientRolesOptions";
import ParticipantContractorRolesOptions from "constants/selectOptions/ParticipantContractorRolesOptions";
import FilterLayout from "Layout/FilterLayout";
import { FormEvent, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { TInvoiceFilter } from "types/TInvoiceFilter";
import { TStatisticsFilter } from "types/TStatisticsFilter";
import { DatePickerField, PredictiveSearchField, selectColorSchemes, SelectField } from "ui";
import useOrdAccountsList from "utils/useOrdAccountsList";
import { getContractList } from "../../../api/contract_list";

type Props = {
  filter: TStatisticsFilter;
  onFilterClick: (_: TInvoiceFilter) => void;
  onClose: () => void;
};

export const StatisticsFilter = ({ filter, onFilterClick, onClose }: Props) => {
  const [searchContractsValue, setSearchContractsValue] = useState<string>();

  const {
    options: ordAccountsTitlesOptions,
    isLoading: ordAccountsLoading,
    isError: ordAccountsError
  } = useOrdAccountsList();

  const [statusTitlesOptions, setStatusTitlesOptions] = useState(
    filter.ord_account?.length ? onlyErirOptions : combinedStatusOptions
  );

  const { control, watch, reset, setValue } = useForm({
    defaultValues: filter
  });

  const {
    mutate: contractsRequest,
    isLoading: contractsLoading,
    data: contractsResponse
  } = useMutation({
    mutationFn: () => {
      const contract = watch('contract')
      return getContractList({
        search: searchContractsValue && String(searchContractsValue) || contract && String(contract) || '',
      })
    }
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onFilterClick(watch());
  };

  const onReset = () => {
    reset(InvoiceFilterDV);
    onFilterClick(InvoiceFilterDV);
  };

  return (
    <FilterLayout onSubmit={onSubmit} onReset={onReset} onClose={onClose}>
      <SelectField
        name="combined_status"
        control={control as unknown as Control<FieldValues, any>}
        title="Статус"
        isMulti
        options={statusTitlesOptions}
        colorSchemes={{
          4: selectColorSchemes.yellow,
          5: selectColorSchemes.green,
          6: selectColorSchemes.red
        }}
      />
      <PredictiveSearchField
        name="contract"
        title="Договор"
        optionKey={["contract_number", "client", "contractor", "status"]}
        optionKeyLabels={["Номер договора", "client", "contractor", "hidden"]}
        inputValue={searchContractsValue}
        setInputValue={setSearchContractsValue}
        control={control as unknown as Control<FieldValues, any>}
        isLoading={contractsLoading}
        request={contractsRequest}
        response={contractsResponse}
        clearable
      />
      <SelectField
        name="ord_account"
        control={control as unknown as Control<FieldValues, any>}
        title="Аккаунт в ОРД"
        isMulti
        options={ordAccountsTitlesOptions}
        disabled={ordAccountsLoading || ordAccountsError}
        onChange={(newVal) => {
          if (newVal && (newVal as (string | number)[]).length) {
            setValue(
              "combined_status",
              watch("combined_status")?.filter((st) => st === 4 || st === 5 || st === 6)
            );
            setStatusTitlesOptions(onlyErirOptions);
          } else {
            setStatusTitlesOptions(combinedStatusOptions);
          }
        }}
      />
      <DatePickerField
        name="start_date"
        control={control as unknown as Control<FieldValues, any>}
        title="Дата начала периода размещения"
        returnFormat="yyyy-MM-dd"
      />
      <DatePickerField
        name="end_date"
        control={control as unknown as Control<FieldValues, any>}
        title="Дата окончания периода размещения"
        returnFormat="yyyy-MM-dd"
      />
    </FilterLayout>
  );
};
