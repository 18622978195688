import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { ID } from "models";
import { TAPIResponseError } from "types/ResponseTypes";

export const deleteStatistics = (id: ID) => {
    return axios
        .delete(`/api/v2/statistics/${id}/`, {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken"),
                "Content-Type": "application/json;charset=utf-8"
            },
            timeout: 1000
        })
        .then((): "success" => "success")
        .catch((err: AxiosError) => (err?.response?.data as TAPIResponseError) || "success");
};
