import EditorFooter from "Layout/EditorFooter";
import { ID } from "models";
import {useLayoutEffect, useMemo, useState} from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { TEditorFooters } from "types/TEditorFooters";
import { scrollToError } from "utils/scrollToError";
import { StatisticsForm, TStatisticsForm } from "./components/form";
import {useMutation} from "react-query";
import onErrorMutation from "utils/onErrorMutation";
import {AxiosError} from "axios";
import {useLoadStatistics} from "./hooks/useLoadStatistics";
import {editStatistics} from "api/statistics_edit";
import {deleteStatistics} from "api/statistics_delete";
import {useNavigate} from "react-router-dom";

type Props = {
  id: ID;
};

enum PossibleStatuses {
  DRAFT = 1,
  ACTIVE = 2,
  LOADING = 3,
};

const StatisticsEditForm = ({ id }: Props) => {
  const statisticsLastSearch = useSelector(
    (state: StateProps) => state.LastSearchParams.statistics
  );
  const [amountFromAllocationsFile, setAmountFromAllocationsFile] = useState<number>();
  const [status, setStatus] = useState<PossibleStatuses>(PossibleStatuses.LOADING);
  const [isFormReady, setIsFormReady] = useState(false);

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {} as TStatisticsForm
  });

  const contract = watch('contract')
  const start_date = watch('start_date')
  const end_date = watch('end_date')
  const statistics_file = watch('statistics_file')

  const {statistics} = useLoadStatistics(id)

  const { mutate: edit, isLoading: isEditLoading } = useMutation({
    mutationFn: editStatistics,
    onSuccess: () => navigate('/statistics'),
    onError: (err) => onErrorMutation(err as AxiosError, setError)
  });

  const { mutate: del, isLoading: isDeleteLoading } = useMutation({
    mutationFn: deleteStatistics,
    onSuccess: () => navigate('/statistics'),
    onError: (err) => onErrorMutation(err as AxiosError, setError)
  });

  const isLoading = useMemo(() => {
    return isEditLoading || isDeleteLoading;
  }, [isEditLoading, isDeleteLoading])

  const onSubmit = handleSubmit(
    (_, event) => {
      if (contract) edit({
        id,
        statistic: {
          contract: Number(contract),
          start_date,
          end_date,
          statistics_file,
          status
        }
      })

    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  const buttons: TEditorFooters<PossibleStatuses> = {
    [PossibleStatuses.DRAFT]: (
      <EditorFooter
        left={
          <>
            <Button
              type="submit"
              color="danger"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                del(id);
              }}
            >
              Удалить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus(PossibleStatuses.DRAFT)}
            >
              Сохранить черновик
            </Button>
          </>
        }
        right={
          <>
            <Button
              href={`#/statistics/${statisticsLastSearch}`}
              tag="a"
              color="primary"
              outline
              disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus(PossibleStatuses.ACTIVE)}
            >
              Отправить в ЕРИР
            </Button>
          </>
        }
      />
    ),
    [PossibleStatuses.ACTIVE]: (
      <EditorFooter
        right={
          <>
            <Button
              href={`#/statistics/${statisticsLastSearch}`}
              tag="a"
              color="primary"
              outline
              disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus(PossibleStatuses.ACTIVE)}
            >
              Отправить в ЕРИР
            </Button>
          </>
        }
      />
    ),
    [PossibleStatuses.LOADING]: <EditorFooter />
  };

  useLayoutEffect(() => {
    if (statistics) {
      setValue('client', statistics.extra_fields?.contract.client?.id)
      setValue('contractor', statistics.extra_fields?.contract.contractor?.id)
      setValue('contract', statistics.extra_fields?.contract.id)
      setValue('start_date', statistics.start_date)
      setValue('end_date', statistics.end_date)
      setValue('statistics_file', statistics.statistics_file)
      setStatus(statistics.status!);

      setIsFormReady(true);
    }
  }, [statistics]);

  return (
    <div>
      <h1>Редактирование статистики без акта</h1>
      {isFormReady && <StatisticsForm
          control={control}
          errors={errors}
          onSubmit={onSubmit}
          buttons={buttons[status]}
          watch={watch}
          setAmountFromAllocationsFile={setAmountFromAllocationsFile}
          isDraft={status === PossibleStatuses.DRAFT}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          statisticsOldFile={statistics_file ? `/api/v2/statistics/${id}/statistics/` : undefined}
      />}
    </div>
  );
};

export default StatisticsEditForm;
