import { format } from "date-fns";
import creative_type from "dictionaries/creative_type.json";
import creative_status from "dictionaries/creative_status.json";
import { BsPencil } from "react-icons/bs";
import { Table } from "reactstrap";
import IconButton from "ui/IconButton";
import getTitleFromDict from "utils/getTitleFromDict";
import { TCreativeAttributesElementProps } from "../types";
import Tooltip from "ui/Tooltip";
import {ReactNode} from "react";
import {AttributesSyncStatus} from "ui/AttributesDropdown/helpers/syncStatus";
import {creativesFormsObject} from "constants/CreativesConstants";
import { useKktuList } from "utils/useKKTUList";

const AttributesBody = ({
  attributes,
  toggleChangeContractModal,
  toggleChangeGroupModal,
  isContractEditable
}: TCreativeAttributesElementProps) => {
  if (attributes === "loading") return <p>Загрузка...</p>;

  if (attributes === "error") return <p>Произошла ошибка загрузки</p>;

  const {list: KKTUOptions} = useKktuList()

  const isArchived = attributes.status === 3;
  const hasSyncStatus = !!attributes.sync_status?.length

  return (
    <Table borderless hover className="mb-0">
      <tbody>
        <tr>
          <td>Финальный договор</td>
          <td>
            {attributes.final_contract?.title || attributes.final_contract?.id ? (
              <a
                href={`#/contract/${attributes.final_contract.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {attributes.final_contract?.title || attributes.final_contract.id}
              </a>
            ) : (
              "-"
            )}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Изначальный договор</td>
          <td>
            {attributes.initial_contract?.title || attributes.initial_contract?.id ? (
              <a
                href={`#/contract/${attributes.initial_contract.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {attributes.initial_contract?.title || attributes.initial_contract.id}
              </a>
            ) : (
              "-"
            )}
          </td>
          <td>
            {
              isContractEditable ? (
                <IconButton
                  id="change-contract-button"
                  outline
                  isFullWidth
                  text="Изменить договор"
                  icon={<BsPencil size={14} /> as ReactNode}
                  onClick={toggleChangeContractModal}
                  isNoWrap
                />
              ) : <Tooltip
                id="change-contract-button"
                maxWidth="20em"
                target={
                  <div id="change-contract-button">
                    <IconButton
                      outline
                      isFullWidth
                      text="Изменить договор"
                      icon={<BsPencil size={14} /> as ReactNode}
                      isNoWrap
                      disabled
                    />
                  </div> as ReactNode
                }
              >
                Изменение договора невозможно, так&nbsp;как по&nbsp;креативам в&nbsp;составе группы
                была подана статистика.
              </Tooltip>
            }
          </td>
        </tr>
        <tr>
          <td>Группа креативов</td>
          <td>{attributes.group?.label}</td>
          <td>
            <IconButton
              outline
              isFullWidth
              text="Сменить группу"
              icon={<BsPencil size={14} /> as ReactNode}
              onClick={toggleChangeGroupModal}
              isNoWrap
            />
          </td>
        </tr>
        <tr>
          <td>Токен</td>
          <td>{attributes.marker || "-"}</td>
          <td></td>
        </tr>
        <tr>
          <td>Тип рекламной кампании</td>
          <td>{getTitleFromDict(attributes.advertisement_type, creative_type)}</td>
          <td></td>
        </tr>
        <tr>
          <td>Форма распространения</td>
          <td>{getTitleFromDict(attributes.advertisement_form, creativesFormsObject)}</td>
          <td></td>
        </tr>
        <tr>
          <td>Дата начала периода размещения</td>
          <td>
            {attributes.start_date ? format(new Date(attributes.start_date), "dd.MM.yyyy") : "-"}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Дата окончания периода размещения</td>
          <td>{attributes.end_date ? format(new Date(attributes.end_date), "dd.MM.yyyy") : "-"}</td>
          <td></td>
        </tr>
        <tr>
          <td>Особенности рекламной кампании</td>
          <td>{attributes.peculiarities}</td>
          <td></td>
        </tr>
        <tr>
          <td>Коды ККТУ</td>
          <td>
            {KKTUOptions.find(kktu => attributes.kktu === kktu.value)?.label}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Параметры целевой аудитории</td>
          <td>{attributes.target_audience}</td>
          <td></td>
        </tr>
        <tr>
          <td>Общее описание объекта рекламирования</td>
          <td>{attributes.description}</td>
          <td></td>
        </tr>
        {

          isArchived || !hasSyncStatus ? (
              <tr>
                <td>Статус</td>
                <td>{getTitleFromDict(attributes.status, creative_status)}</td>
                <td></td>
              </tr>
          ) : <AttributesSyncStatus title={'Статус'} syncStatus={attributes.sync_status} />
        }
      </tbody>
    </Table>
  );
};

export default AttributesBody;
