import { ReactNode, useEffect, useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import IconButton from "ui/IconButton";
import ProgressLoader from "ui/ProgressLoader";
import getFormatBytes from "utils/getFormatBytes";
import getTextShortened from "utils/getTextShortened";
import Body from "./Body";

export type TDisplayObj = {
  url?: string;
  name?: string;
  size?: number;
  extension?: string;
  text?: string;
};

type Props = {
  displayObj: TDisplayObj | null;
  isOpen: boolean;
  toggle: () => void;
};

const AttachmentPreviewModal = ({ displayObj, toggle, isOpen }: Props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen) setLoading(true);
  }, [isOpen]);

  if (!displayObj) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="p-0 modal-shadow-none">
      <ModalHeader toggle={toggle}>{displayObj?.name}</ModalHeader>
      {displayObj ? (
        <>
          <ModalBody className="d-block justify-content-center overflow-auto p-0">
            {loading ? <ProgressLoader /> as ReactNode : <div style={{ height: "5px", marginBottom: "5px" }} /> as ReactNode}
            <div style={{ height: "500px", marginBottom: "10px", paddingInline: "10px" }}>
              <Body displayObj={displayObj} onLoad={() => setLoading(false)} />
            </div>
          </ModalBody>
          {displayObj.text && displayObj.url ? (
            <ModalFooter>
              <IconButton
                icon={<MdOutlineFileDownload size={20} /> as ReactNode}
                text="Скачать"
                href={displayObj.url}
                download={getTextShortened(displayObj.text, 10) + ".txt"}
              />
            </ModalFooter> as ReactNode
          ) : null}
          {!displayObj.text ? (
            <ModalFooter>
              {displayObj.size ? <p>{getFormatBytes(displayObj.size)}</p> as ReactNode : ""}
              <IconButton
                icon={<MdOutlineFileDownload size={20} /> as ReactNode}
                text="Скачать"
                href={displayObj.url}
                download
              />
            </ModalFooter> as ReactNode
          ) : null}
        </> as ReactNode
      ) : null}
    </Modal>
  );
};

export default AttachmentPreviewModal;
