import axios from "axios";
import { APIData, CountryCodeApi } from "./types";

export const getCountryCode = (): Promise<APIData<CountryCodeApi>> => {
  return axios
    .get('/api/v1/dictionaries/country_code/', {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      },
    })
    .then((res) => {
      return res.data as Promise<APIData<CountryCodeApi>>;
    });
};
