import React from "react";
import {SelectField} from "../../../ui";
import getErrorText from "utils/getErrorText";
import {Control, FieldErrors, FieldValues} from "react-hook-form/dist/types";
import { useKktuList } from "utils/useKKTUList";

interface KktuSelectProps {
    control:Control<FieldValues>;
    errors: FieldErrors<FieldValues>;
    isDisabled: boolean;
    isRequired: boolean;
}
export const KktuSelect = ({control, errors, isDisabled, isRequired}: KktuSelectProps) => {
  const {list: options, isLoading} = useKktuList()

    return (
        <>
            <SelectField
                name="kktu"
                title="Справочник ККТУ"
                placeholder='Коды ККТУ'
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "kktu", "Пожалуйста, выберите значение из справочника ККТУ.")}
                options={options}
                chunkSize={10}
                infoText="Код ККТУ самостоятельно определяют рекламодатели и передают участниками, регистрирующим креатив. Выберите из списка, к какой категории относится товар или услуга в креативе."
                disabled={isDisabled || isLoading}
                isRequired={isRequired}
                noMargin
                emptyMessage={'Указанный код/категория не найден в справочнике ККТУ. Пожалуйста, введите корректное значение.'}
            />
            <a href={'/static/core/pdf/kktu.pdf'} rel={'noreferrer'} target={'_blank'}>Смотреть список кодов ККТУ</a>
        </>
    );
};
