import cn from "classnames";
import { format, getMonth, getYear } from "date-fns";
import { ru } from "date-fns/locale";
import get from "lodash/get";
import DatePicker from "react-datepicker";
import { Control, Controller, FieldErrors, FieldValues, Validate } from "react-hook-form";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IMaskInput } from "react-imask";

import Select from "react-select";
import { Button, FormGroup } from "reactstrap";
import FieldFeedback from "ui/FieldFeedback";
import FieldLabel from "ui/FieldLabel";
import { getYears, months } from "./helpers";

type Props = {
  name: string;
  title: string;
  control: Control<FieldValues, any>;
  errors?: FieldErrors<FieldValues>;
  isRequired?: boolean;
  isStared?: boolean;
  isNotClearable?: boolean;
  errorText?: string;
  disabled?: boolean;
  returnFormat?: string;
  extraAction?: (_: string | Date | null) => void;
  validate?: Validate<any, FieldValues> | Record<string, Validate<any, FieldValues>> | undefined;
  infoText?: string;
};

export const DatePickerField = ({
  name,
  title,
  control,
  errors,
  isRequired,
  isStared,
  isNotClearable,
  errorText,
  disabled,
  returnFormat,
  extraAction,
  validate,
  infoText,
}: Props) => {
  const years = getYears();
  const isInvalid = !!errors && !!get(errors, name);
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired, validate }}
      render={({ field: { value, onChange, onBlur } }) => (
        <FormGroup>
          <FieldLabel
            title={title}
            isRequired={isRequired || isStared}
            check
            for={name}
            style={{ marginBottom: ".5em" }}
            info={
              infoText
                ? {
                  text: infoText,
                    id: `info_${name}`
                  }
                : undefined
            }
          />
          <DatePicker
            id={name}
            placeholderText={title}
            selected={value ? new Date(value as string | Date | number) : null}
            onChange={(value) => {
              let newValue: string | Date | null = value;
              if (returnFormat && value) {
                newValue = format(value, returnFormat);
              }
              onChange(newValue);
              if (extraAction) extraAction(newValue);
            }}
            onChangeRaw={(e) =>
              e && !(e.target as HTMLInputElement).value?.match(/\d/g) && onChange(undefined)
            }
            locale={ru}
            closeOnScroll
            customInput={<IMaskInput mask={Date} className="form-control" />}
            dateFormat="dd.MM.yyyy"
            disabled={disabled}
            className={cn({
              "is-invalid": isInvalid
            })}
            wrapperClassName={cn("position-relative", {
              "is-invalid": isInvalid
            })}
            popperPlacement="bottom-start"
            isClearable={!isNotClearable}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => (
              <div
                style={{
                  marginInline: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "2px"
                }}
              >
                <Button
                  outline
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  style={{ border: "none" }}
                >
                  <FaChevronLeft />
                </Button>
                <Select
                  options={years}
                  styles={{
                    control: (styles): any => ({ ...styles, border: "none", width: "120px" }),
                    menu: (styles): any => ({ ...styles, zIndex: 99 })
                  }}
                  value={years.find((year) => year.value === getYear(date))}
                  components={{ IndicatorSeparator: () => null }}
                  onChange={(newValue) => (newValue ? changeYear(newValue.value) : null)}
                />
                <Select
                  options={months}
                  styles={{
                    control: (styles): any => ({ ...styles, border: "none", width: "120px" }),
                    menu: (styles): any => ({ ...styles, zIndex: 99 })
                  }}
                  value={months.find((month) => month.value === getMonth(date))}
                  components={{ IndicatorSeparator: () => null }}
                  onChange={(newValue) => (newValue ? changeMonth(newValue.value) : null)}
                />
                <Button
                  outline
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  style={{ border: "none" }}
                >
                  <FaChevronRight />
                </Button>
              </div>
            )}
            onBlur={onBlur}
          />
          <FieldFeedback name={name} errors={errors} errorText={errorText} />
        </FormGroup>
      )}
    />
  );
};
