import cn from "classnames";
import get from "lodash/get";
import { useEffect, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { Controller } from "react-hook-form";
import { BsFillFileEarmarkCheckFill, BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { Button, Card, CardBody, CardTitle, List } from "reactstrap";
import FieldLabel from "ui/FieldLabel";
import createNotification from "ui/Notification";
import getDropzoneAcceptFromArray from "utils/getDropzoneAcceptFromArray";
import { ERROR_DECODE, SERVER_ERROR_MESSAGE } from "./constants";
import { Props } from "./types";

const CARD_COLORS = {
  fail: "danger",
  success: "success"
};

const createBase64Sheet = (base64Str?: string) =>
  base64Str
    ? `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Str}`
    : null;

export const DropZoneField = ({
  name,
  title,
  message,
  control,
  errors,
  currentErrorsObject,
  currentSuccessObject,
  fileTypes,
  exampleLink,
  exampleTitle,
  children,
  uploadFunction,
  disabled,
  isRequired,
  isStared,
  responsePrintField = "file_path",
  setAdditionalData,
  buttonText,
  oldFile,
  noErrorsNotification,
  buttonInTitle
}: Props) => {
  const [uploadStatus, setUploadStatus] = useState<"success" | "fail">("fail");
  const [currentlyDisabled, setCurrentlyDisabled] = useState(disabled);
  const [currentMessage, setCurrentMessage] = useState<null | string>(null);
  const [currentErrorsList, setCurrentErrorsList] = useState<null | string[]>(null);
  const [currentFileName, setCurrentFileName] = useState<null | string>(null);
  const [logFile, setLogFile] = useState<null | string>(null);
  const [uploadedFile, setUploadedFile] = useState<null | string>(null);

  useEffect(() => {
    if (currentErrorsObject) {
      setUploadStatus("fail");
      setCurrentMessage(currentErrorsObject.error_message || null);
      setCurrentErrorsList(currentErrorsObject.form_errors?.file || null);
      setLogFile(
        createBase64Sheet(
          currentErrorsObject.form_errors?.errors_xls || currentErrorsObject.form_errors?.xls
        )
      );
    } else if (currentSuccessObject) {
      setUploadStatus("success");
      setCurrentMessage(currentSuccessObject.data?.message || null);
      setCurrentErrorsList(null);
      setLogFile(createBase64Sheet(currentSuccessObject.data?.xls));
    }
  }, [currentErrorsObject, currentSuccessObject]);

  return (
    <div>
      <FieldLabel
        isBig
        isRequired={isRequired || isStared}
        title={title}
        buttonInTitle={buttonInTitle}
      />
      <div className="row align-items-center mb-2" style={{ marginInline: 0 }}>
        <div className="col-md-2 mb-3 mb-md-0 d-flex flex-column gap-3">
          {exampleLink && typeof exampleLink === "string" ? (
            <a href={exampleLink} className="text-center" target="_blank" rel="noreferrer">
              {exampleTitle || "Шаблон"}
            </a>
          ) : null}
          {exampleLink && typeof exampleLink !== "string" ? (
            <List type="unstyled" className="col-sm-2" style={{ paddingLeft: 0 }}>
              {exampleLink.map((link, index) => (
                <li key={link} className={cn("lh-sm", { "mt-2": index })}>
                  <a href={link} target="_blank" rel="noreferrer">
                    {exampleTitle && typeof exampleTitle !== "string"
                      ? exampleTitle[index]
                      : `Шаблон ${index + 1}`}
                  </a>
                </li>
              ))}
            </List>
          ) : null}
          {oldFile ? (
            <a href={oldFile} className="btn btn-primary w-100" target="_blank" rel="noreferrer">
              Ранее прикрепленный файл
            </a>
          ) : null}
        </div>
        <Controller
          name={name}
          control={control}
          rules={{ required: isRequired }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Dropzone
              onDropAccepted={(files: File[]) => {
                setCurrentlyDisabled(true);
                const [file] = files;

                setUploadedFile(URL.createObjectURL(file));
                setCurrentFileName(file.name);

                const formData = new FormData();
                formData.append("file", file);

                if (uploadFunction) {
                  uploadFunction(formData)
                    .then((res) => {
                      if (res && res.status) {
                        const { data: resData } = res;
                        if (responsePrintField) onChange(resData?.[responsePrintField]);
                        if (setAdditionalData && resData?.[setAdditionalData.field]) {
                          setAdditionalData.fn(resData?.[setAdditionalData.field]);
                        }
                        setCurrentMessage(null);
                        setCurrentErrorsList(null);
                        setLogFile(null);
                        return;
                      }

                      if (res && res.status === undefined) {
                        setCurrentMessage(SERVER_ERROR_MESSAGE);
                        return;
                      }

                      if (res && res.status === false) {
                        if (responsePrintField) onChange(null);
                        const errorMessage = res?.error_message || res?.form_errors?.error_message;
                        if (errorMessage) setCurrentMessage(errorMessage || null);
                        if (res?.form_errors?.errors_xls)
                          setLogFile(createBase64Sheet(res.form_errors.errors_xls));
                        if (res?.form_errors?.xls)
                          setLogFile(createBase64Sheet(res.form_errors.xls));
                        if (res?.form_errors?.file)
                          setCurrentErrorsList(res?.form_errors?.file || null);
                        return;
                      }
                      setCurrentlyDisabled(false);
                    })
                    .catch((err) => console.error(err));
                } else {
                  setCurrentMessage(null);
                  setCurrentErrorsList(null);
                  setLogFile(null);
                  onChange(formData);
                  setCurrentlyDisabled(false);
                }
                onBlur();
              }}
              onDropRejected={(err: FileRejection[]) => {
                err.forEach(({ errors }) =>
                  createNotification(
                    "error",
                    errors
                      .map(({ code }) => ERROR_DECODE[code as keyof typeof ERROR_DECODE])
                      .join(". ")
                  )
                );
                onBlur();
              }}
              accept={getDropzoneAcceptFromArray(fileTypes)}
              maxFiles={1}
              disabled={currentlyDisabled}
            >
              {({ getRootProps, getInputProps }) => {
                return (
                  <>
                    <div
                      {...getRootProps()}
                      aria-disabled={currentlyDisabled}
                      className={cn(
                        "dropzone-wrapper",
                        "dropzone-wrapper-sm",
                        "flex-column",
                        "flex-md-row",
                        "gap-3",
                        "justify-content-center",
                        "py-3",
                        {
                          // "w-75": exampleLink,
                          "dropzone-wrapper-error": !!errors && !!get(errors, name),
                          "col-md-10": exampleLink || oldFile
                          // "dropzone-wrapper-error": !!errors && !!errors[name]
                        }
                      )}
                    >
                      <input {...getInputProps()} />
                      <div className="dropzone-content w-75 my-5 my-md-0 flex-column">
                        <p className="h5">
                          {currentFileName || message || "Прикрепите файл"}
                          {value ? (
                            <BsFillFileEarmarkCheckFill style={{ marginLeft: "5px" }} />
                          ) : (
                            <BsFillFileEarmarkPlusFill style={{ marginLeft: "5px" }} />
                          )}
                        </p>
                        {oldFile ? (
                          <p>Ранее прикрепленный файл можно скачать по ссылке слева</p>
                        ) : null}
                      </div>
                      <div
                        className={cn("flex-column", "w-auto", "gap-3", {
                          "d-none": !uploadedFile,
                          "d-flex": uploadedFile
                        })}
                      >
                        <a
                          href={uploadedFile || ""}
                          download={`downloaded-${currentFileName}`}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-outline-secondary"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {oldFile
                            ? "Скачать новый прикрепленный файл"
                            : "Скачать прикрепленный файл"}
                        </a>
                        <Button
                          color="warning"
                          outline
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentMessage(null);
                            setCurrentErrorsList(null);
                            setLogFile(null);
                            onChange(null);
                            setUploadedFile(null);
                            setCurrentFileName(null);
                            setCurrentlyDisabled(false);
                          }}
                        >
                          {oldFile
                            ? "Удалить новый прикрепленный файл"
                            : "Удалить прикрепленный файл"}
                        </Button>
                      </div>
                    </div>
                  </>
                );
              }}
            </Dropzone>
          )}
        />
      </div>
      {currentMessage || currentErrorsList ? (
        <Card
          color={CARD_COLORS[uploadStatus]}
          outline
          style={{ borderWidth: "1px", marginBottom: "10px" }}
        >
          <CardBody>
            <CardTitle tag="h5" className={currentErrorsList || logFile ? "" : "mb-0"}>
              {currentMessage || "При обработке файла были найдены ошибки"}
            </CardTitle>
            {currentErrorsList ? (
              <List type="unstyled" style={{ marginBottom: 0 }}>
                {currentErrorsList.slice(0, 10).map((err) => (
                  <li key={err}>{err}</li>
                ))}
                {currentErrorsList.length - 10 > 0 ? (
                  <li>И еще {currentErrorsList.length - 10} стр.</li>
                ) : null}
              </List>
            ) : null}
            {logFile ? (
              <>
                {!currentErrorsList && noErrorsNotification ? (
                  <p style={{ marginBottom: 0 }}>{noErrorsNotification}</p>
                ) : null}
                <Button
                  type="button"
                  href={logFile}
                  download={`log_${new Date().toLocaleDateString()}.xlsx`}
                  style={{ marginTop: "10px" }}
                  color={CARD_COLORS[uploadStatus]}
                >
                  {buttonText || "Скачать отчет по всем ошибкам"}
                </Button>
              </>
            ) : null}
          </CardBody>
        </Card>
      ) : null}
      {children}
    </div>
  );
};
