import { useQuery } from "react-query";
import { APIData, KKTUApi } from "api/types";
import { AxiosError } from "axios";
import { getKKTUDictionary } from "api/get_kktu_dictionary";
import { useMemo } from "react";

type UseKKTUListReturnType = {
  list: {
    value: string,
    label: string,
  }[],
  isLoading: boolean,
  isError: boolean
}

export const useKktuList = (): UseKKTUListReturnType => {
  const {data, isLoading, isError} = useQuery<APIData<KKTUApi>, AxiosError>({
    queryKey: ['kktu_dict'],
    queryFn: getKKTUDictionary,
  })



  const isThirdLevel = (value: string): boolean => {
    const dotsCount = (value.split('.') || []).length;
    return !!dotsCount && dotsCount > 2
  }

  const KKTUOptions = useMemo(() => {
    return data?.data.classifiers_of_product_categories.filter(item => isThirdLevel(item.code)).map((kktu) => ({
      value: kktu.code,
      label: kktu.code + " " + kktu.title
    })) || []
  }, [data])

  return {
    list: KKTUOptions,
    isLoading,
    isError
  }
};
