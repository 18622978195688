import invoice_status from "dictionaries/invoice_status.json";
import {ID, Statistic} from "models";
import { useNavigate } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";
import ListingCard from "ui/ListingCard";
import ListingCardsWrapper from "ui/ListingCardsWrapper";
import ListingTable from "ui/ListingTable";
import ListingTableRow from "ui/ListingTableRow";
import ParticipantCell from "ui/ParticipantDisplay/ParticipantCell";
import StatusCell from "ui/StatusDisplay/StatusCell";
import StatusInline from "ui/StatusDisplay/StatusInline";
import getDate from "utils/getDate";
import getActions from "./getActions";
import {useMutation} from "react-query";
import onDeleteMutation from "utils/onDeleteMutation";
import {deleteStatistics} from "api/statistics_delete";

interface Props extends IExportRelatedProps {
  statistics: Statistic[];
  refetch: () => Promise<any>;
}

export const StatisticsTable = ({
  statistics,
  pageIds,
  exportIds,
  setExportIds,
  onExportIdCheck,
  refetch,
}: Props) => {
  const navigate = useNavigate();

  const onRowDoubleClick = (id: ID) => navigate(`/statistics/${id}`);

  const { mutate: del } = useMutation({
    mutationFn: deleteStatistics,
      onSuccess: (response) =>
        onDeleteMutation({
          response,
          refetch,
          successMessage: "Статистика удалена"
        })
    });

  const actions = getActions(del);

  return (
    <>
      <ListingTable
        theadList={[
          "Заказчик",
          "Исполнитель",
          "№&nbsp;договора",
          "Дата договора",
          "№&nbsp;акта",
          "Дата акта",
          "Начало периода",
          "Окончание периода",
          "Статус",
          "Количество креативов",
          "Фактическое число показов",
          "Общее число показов",
          "Общая стоимость показов"
        ]}
        pageIds={pageIds}
        exportIds={exportIds}
        setExportIds={setExportIds}
        tbody={statistics.map((statistic: Statistic) => (
          <ListingTableRow
            key={statistic.id}
            id={statistic.id}
            item={statistic}
            onRowDoubleClick={() => statistic.id && onRowDoubleClick(statistic.id)}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            actions={actions}
            list={
              <>
                <ParticipantCell
                  id={`client${statistic.id}`}
                  name={statistic.extra_fields?.contract?.client?.name}
                  inn={statistic.extra_fields?.contract?.client?.inn}
                />
                <ParticipantCell
                  id={`contractor${statistic.id}`}
                  name={statistic.extra_fields?.contract?.contractor?.name}
                  inn={statistic.extra_fields?.contract?.contractor?.inn}
                />
                <td style={{ minWidth: "100px" }}>
                  {statistic.extra_fields?.contract?.contract_number}
                </td>
                <td style={{ minWidth: "120px" }}>
                  {getDate(statistic.extra_fields?.contract?.contract_date)}
                </td>
                <td style={{ minWidth: "70px" }}>{statistic.extra_fields?.invoice?.invoice_number}</td>
                <td style={{ minWidth: "120px" }}>{getDate(statistic.extra_fields?.invoice?.invoice_date)}</td>
                <td style={{ minWidth: "120px" }}>{getDate(statistic.start_date)}</td>
                <td style={{ minWidth: "125px" }}>{getDate(statistic.end_date)}</td>
                <StatusCell data={statistic} dictionary={invoice_status} />
                <td style={{ minWidth: "120px" }}>{statistic.extra_fields?.creatives_count}</td>
                <td style={{ minWidth: "120px" }}>{statistic.extra_fields?.impressions_fact}</td>
                <td style={{ minWidth: "120px" }}>{statistic.extra_fields?.impressions_plan}</td>
                <td style={{ minWidth: "140px" }}>
                  {statistic.extra_fields?.creative_statistics_amount}
                </td>
              </>
            }
          />
        ))}
      />
      <ListingCardsWrapper>
        {statistics.map((statistic: Statistic) => (
          <ListingCard
            key={statistic.id}
            id={statistic.id}
            item={statistic}
            href={`/statistics/${statistic.id}`}
            actions={actions}
            list={
              <>
                <ListGroupItem>
                  Заказчик: {statistic.extra_fields?.contract?.client?.name}{" "}
                  {statistic.extra_fields?.contract?.client?.inn
                    ? `(${statistic.extra_fields?.contract?.client?.inn})`
                    : ""}
                </ListGroupItem>
                <ListGroupItem>
                  Исполнитель: {statistic.extra_fields?.contract?.contractor?.name}{" "}
                  {statistic.extra_fields?.contract?.contractor?.inn
                    ? `(${statistic.extra_fields?.contract?.contractor?.inn})`
                    : ""}
                </ListGroupItem>
                <ListGroupItem>
                  № договора: {statistic.extra_fields?.contract?.contract_number}
                </ListGroupItem>
                <ListGroupItem>
                  Дата договора: {getDate(statistic.extra_fields?.contract?.contract_date)}
                </ListGroupItem>
                <ListGroupItem>Период: {statistic.invoice}</ListGroupItem>
                <ListGroupItem>
                  Статус: <StatusInline data={statistic} dictionary={invoice_status} />
                </ListGroupItem>
                <ListGroupItem>Количество креативов: {statistic.extra_fields?.creatives_count}</ListGroupItem>
                <ListGroupItem>
                  Фактическое число показов: {statistic.extra_fields?.impressions_fact}
                </ListGroupItem>
                <ListGroupItem>Общая стоимость услуг: {statistic.extra_fields?.creative_statistics_amount}</ListGroupItem>
              </>
            }
          />
        ))}
      </ListingCardsWrapper>
    </>
  );
};
