import { ActionSelectorProps } from "ui/ActionSelector";
import {UseMutateFunction} from "react-query";
import {TAPIResponseError} from "../../../types/ResponseTypes";

const getActions = (
    del: UseMutateFunction<"success" | TAPIResponseError, unknown, string | number, unknown>
): ActionSelectorProps[] => {
  return [
    {
      title: "Редактировать",
      calcHref: (id) => ({ href: `#/statistics/${id}/` })
    },
    {
      title: "Удалить",
      onClick: (id) => (id ? del(id) : null),
      calcIsDisabled: (item) => item.status === 1
    }
  ];
};

export default getActions;
