import {Invoice, Statistic} from "models";
import { TInvoiceFilter } from "types/TInvoiceFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsStatistics extends TSearchParamsPropsNoFilter {
  filter?: TInvoiceFilter;
}

export const getStatisticsList = (props?: IPropsStatistics): Promise<APIListData<Statistic>> => {
  return fetch(
    props === undefined ? `/api/v2/statistics/` : `/api/v2/statistics/?${getSearchParams(props)}`,
    {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  ).then((res) => {
    const result = res.json() as Promise<APIListData<Statistic>>;
    return result;
  });
};
