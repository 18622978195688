import { APIData, KKTUApi } from "./types";
import axios from "axios";

export const getKKTUDictionary = (): Promise<APIData<KKTUApi>> => {
  return axios
    .get('/api/v1/dictionaries/kktu/', {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      },
    })
    .then((res) => {
      return res.data as Promise<APIData<KKTUApi>>;
    });
};
