import { TInvoiceFilter } from "types/TInvoiceFilter";
import { TSearchParamsRequired } from "types/TSearchParams";
import { TStatisticsFilter } from "../../types/TStatisticsFilter";

export const defaultSearchParams: TSearchParamsRequired<TStatisticsFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: "-id",
  filter: {
    ord_account: [],
    combined_status: [],
    start_date: undefined,
    end_date: undefined,
    contract: [],
  }
};

export const defaultExportLink = "/api/v2/statistics/xls/";
